import React from 'react';
import Particles from "react-tsparticles";
// import logo from './logo.svg';
import './App.css';
import particlesOptions from "./particles.json";

function App() {
    return (
        <div className="App">
            <Particles options={particlesOptions}/>
        </div>
    );
}

export default App;

// function App() {
//     return (
//         <div className="App">
//             <Particles options={particlesOptions}/>
//             <header className="App-header">
//                 <img src={logo} className="App-logo" alt="logo"/>
//                 <p>
//                     Edit <code>src/App.js</code> and save to reload.
//                 </p>
//                 <p>
//                     Edit <code>src/particles.json</code> to customize Particles, then save to reload.
//                 </p>
//                 <a
//                     className="App-link"
//                     href="https://reactjs.org"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                 >
//                     Learn React
//                 </a>
//                 <a
//                     className="App-link"
//                     href="https://particles.matteobruni.it"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                 >
//                     See Particles samples
//                 </a>
//             </header>
//         </div>
//     );
// }

// export default App;
